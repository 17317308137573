@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable-dynamic-subset.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 특정 UI css 파일은 이곳에서 import 됩니다. */
@import "./main.css";
@import "./nftDetail.css";
@import "./nftList.css";
@import "./register.css";
@import "./nftType.css";
@import "./myPage.css";

@layer base {
  @font-face {
    font-family: "Pretendard Variable";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
      format("woff");
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: "Pretendard Variable";
  }
}

/* 모달 */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 7777;
  background-color: rgba(246, 242, 242, 0.702);
}

.modal-content {
  @apply flex rounded-[14px] shadow-md;
  width: auto;
  height: auto;
  padding: 3rem;
  background-color: #fff;
  position: relative;
}

.modal-close-icon {
  @apply rounded-full m-3 cursor-pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  color: #717171;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  cursor: pointer;
  right: 0px;
  top: 0px;
}

/* select placeholder 설정 */
select option[value=""][disabled] {
  display: none;
}

input:focus,
select,
textarea,
option input.form-control:focus {
  @apply focus:border-brand100 focus:ring-0 !important;
}

input:checked {
  @apply focus:border-current focus:ring-0 !important;
}

.Toastify__toast-body {
  white-space: pre-line !important;
  z-index: 9999 !important;
}
