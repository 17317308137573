@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .mypage-mydata-edit-button {
    @apply border border-gray80 rounded-[30px] bg-white h-[34px] w-[114px] text-gray80 sm:mt-0 mt-[10px];
  }

  .mypage-connect-network {
    @apply flex flex-col sm:ml-[160px];
  }
}
