@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nft-list-select {
    @apply border-x-0 border-t-0 text-[#E06612]  w-[149px] bg-white bg-opacity-0 border-b-[#F5D9C6] border-b-[2px];
  }

  .nft-list-card {
    @apply transition-all duration-200 ease-out;
    transition: 0.2s all ease-out;
  }
}
