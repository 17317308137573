@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* main페이지 최상단  */
  .nft-detail-title-button {
    @apply rounded-[30px] h-[35px] bg-[#470B11]  text-white w-[90px] flex items-center justify-center;
  }

  .nft-detail-title-button span {
    @apply ml-2;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}
