/* */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .register-form-name {
    @apply text-brand100 font-semibold w-[120px];
  }

  .register-basic-button {
    @apply bg-brand100 text-white rounded-[20px] px-3 text-[14px] h-[30px];
  }

  .register-basic-note {
    @apply text-[#e06612] mt-[10px] text-[14px];
  }

  .select-animal-list-body {
    @apply grid-cols-1  border-gray40 cursor-pointer relative z-40 py-2 min-h-[38px] bg-white   items-center justify-center  text-[#8E8E8E]   border w-[230px] text-center rounded-[30px] overflow-auto  max-h-[360px];
  }

  .select-animal-list-body::-webkit-scrollbar {
    display: none;
  }
}
