@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* layout */

  .header-mobile-menu {
    @apply md:hidden flex;
  }

  .header-mobile-menu svg {
    @apply h-[30px] w-[30px] mr-[36px] md:ml-0;
  }

  /*  */
  .main-intro-container {
    @apply flex sm:flex-row flex-col items-center justify-center  sm:mt-[80px];
  }

  /* main페이지 최상단  left */
  .main-left-controller span {
    @apply bg-white bg-opacity-0;
  }

  .main-left-controller svg {
    @apply bg-none text-[#FCC22F] w-[32px] h-[32px];
  }

  .main-right-controller span {
    @apply bg-white bg-opacity-0;
  }

  .main-right-controller svg {
    @apply bg-none text-[#FCC22F] w-[32px] h-[32px];
  }

  /* main 페이지 최상단 right */

  .main-right-content-button {
    @apply sm:w-[180px] sm:h-[48px] bg-[#E06611] text-white sm:text-[22px] w-[120px] h-[36px] text-[16px] rounded-[40px] font-semibold;
  }

  /* 긴급구조 슬라이더 */

  .main-left-controller_emergency span {
    @apply bg-white bg-opacity-0;
  }

  .main-left-controller_emergency svg {
    @apply bg-none text-[#FCC22F] w-[32px] h-[32px];
  }

  .main-right-controller_emergency span {
    @apply bg-white bg-opacity-0;
  }

  .main-right-controller_emergency svg {
    @apply bg-none text-[#FCC22F] w-[32px] h-[32px];
  }

  /* main페이지 최하단 배너  */
  .main-bottom-banner {
    @apply w-full h-[360px] mt-[200px] flex items-center justify-center flex-col;
    background-image: url("../common/images/sample/main-bottom-bg.png");
  }

  .main-bottom-banner h3 {
    @apply text-[32px] font-bold text-white;
  }

  .main-bottom-banner h5 {
    @apply text-[20px] text-white mt-[10px];
  }

  .main-bottom-banner button {
    @apply text-white text-center mt-[30px] bg-[#FCC22F] w-[240px] text-[24px] rounded-[30px] py-1 font-semibold;
  }

  .register-list-body {
    @apply w-fit max-h-[110px]  overflow-y-scroll p-3 rounded-lg bg-opacity-75;
  }
}

/* .register-list-body::-webkit-scrollbar {
  display: none;
} */
