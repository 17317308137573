/* */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nfttype-type-button {
    @apply border-[2px] border-brand100 font-semibold rounded-[30px] w-[100px] text-brand100 flex items-center justify-center h-[36px];
  }

  .nfttype-content-title {
    @apply text-brand100 font-semibold w-[160px];
  }

  .nfttype-content-price {
    @apply text-[#470B11] font-semibold sm:text-[22px] text-[16px];
  }

  .nfttype-content-price-content {
    @apply ml-1 text-[#470B11] font-semibold text-[18px] text-opacity-95;
  }
  .nfttype-img {
    @apply object-fill  sm:max-h-[560px] w-full h-full rounded-[14px] z-50 mt-[10px];
  }

  .nfttype-main-title {
    @apply sm:text-[48px] text-[36px] sm:mt-0 mt-[10px] font-bold text-[#e06612];
  }

  .nfttype-content-div {
    @apply flex sm:flex-row flex-col w-full mt-[50px];
  }
}
